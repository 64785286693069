<template>
    <div>
        <b-form>
            <b-row >
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Email Inversionista" label-for="basicInput">                        
                        <b-form-input v-model = "email" placeholder="Digite el email"></b-form-input>
                    </b-form-group>                    
                </b-col> 
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Número de Documento" label-for="basicInput">                        
                        <b-form-input v-model = "documentNumber" placeholder="Digite el documento"></b-form-input>
                    </b-form-group>                    
                </b-col>
                <b-col md="1.5" xl="1.5" class="mb-1">
                    <b-form-group label="Estado" label-for="basicInput">                        
                        <b-form-select v-model ="transferStatus" :options="transferStatusList" 
                        value-field="transferStatus" text-field="transferStatus"/>
                    </b-form-group>
                </b-col>
                <b-col md="2" xl="2" class="mb-1">
                    <b-form-group label="Fecha de Inicio" label-for="example-datepicker">
                    <b-form-datepicker show-decade-nav="true" v-model="startDate" id="example-datepicker2" class="mb-1"/>
                    </b-form-group>
                </b-col>
                <b-col md="" xl="2" class="mb-1">
                    <b-form-group label="Fecha Final" label-for="example-datepicker">
                    <b-form-datepicker show-decade-nav="true" v-model="endDate" id="example-datepicker3" class="mb-1"/>
                    </b-form-group>
                </b-col>

                <b-col md="1.7" xl="1.7" class="mt-2">
                    <b-button  variant="primary" @click="getMoneyRequestsToApprove" >
                        <feather-icon icon="SearchIcon" class="mr-50"/>Filtrar
                    </b-button>                        
                </b-col>
                <b-col md="2" xl="2" class="mt-2">
                    <b-button  variant="warning" @click="GetMoneyRequestDetailsFile">
                        <feather-icon icon="DownloadIcon" class="mr-50"/>Descargar Archivo
                    </b-button>                       
                </b-col> 
            </b-row>
        </b-form>  

        <b-card-code no-body>

            <b-table 
            v-if="moneyRequests.length>0"
            ref="selectableTable"
            :fields="fields" 
            :items="moneyRequests" 
            responsive
            selectable
            class="mb-0"
            :select-mode="selectMode"
            @row-selected="onRowSelected">

                <template #cell(documentNumber)="data">
                    {{(data.item.documentNumber)}}
                </template> 

                <template #cell(amountToTransfer)="data">
                    ${{parseFloat(data.item.amountToTransfer).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} COP
                </template>

                <template #cell(CreateDate)="data">
                    {{((data.item.createDate).substring(0,10))}}
                </template> 

                 <template #cell(userBalance)="data">
                    ${{parseFloat(data.item.userBalance).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} COP
                </template> 

                <template #cell(transferStatus)="data">
                    <b-badge v-if="data.item.transferStatus == 'Pendiente'" pill variant="warning">Pendiente</b-badge>
                    <b-badge v-if="data.item.transferStatus == 'Rechazado' || data.item.deleteDate" pill variant="danger">Rechazado</b-badge>
                    <b-badge v-if="data.item.transferStatus == 'Aprobado' || data.item.isTransferred" pill variant="success">Aprobado</b-badge>
                    <b-badge v-if="data.item.transferStatus == 'En Proceso'" pill variant="info">En Proceso</b-badge>
                </template>  

            </b-table>
            <b-row v-if="moneyRequests.length <= 0" >
                <b-col xl="4" md="6" lg="4" offset-lg="4" offset-md="3" offset-xl="4"  >
                    <b-alert variant="primary" show>
                        <div class="alert-body">
                            <span><strong>No hay transferencias de dinero pendientes</strong></span>
                        </div>
                    </b-alert>
                </b-col>
            </b-row> 
        </b-card-code>

        <b-modal centered ref="requestMoneyModal" hide-footer title="Que deseas hacer?">
            <b-button v-if="this.requestSelected.transferStatus == 'Pendiente'" class="mt-1" variant="info" block @click="passToIsInProcess">
                <feather-icon icon="ChevronsRightIcon" class="mr-50"/>
                <span class="align-middle">Pasar a "En proceso"</span>
            </b-button>
            <b-button v-if="!this.requestSelected.documentUrl && this.requestSelected.transferStatus !='Rechazado'" class="mt-1" variant="primary" block @click="showLoadFileModal">
                <feather-icon icon="CheckIcon" class="mr-50"/>
                <span class="align-middle">Aprobar Transferencia</span>
            </b-button>
            <b-button v-if="this.requestSelected.documentUrl" class="mt-1" variant="primary" block @click="openDocument(requestSelected.documentUrl)">
                <feather-icon icon="DownloadIcon" class="mr-50"/>
                <span class="align-middle">Descargar Comprobante</span>
            </b-button>
            <b-button v-if="!this.requestSelected.isTransferred" class="mt-1" variant="danger" block @click="showModalRejectMoneyRequest">
                <feather-icon icon="XIcon" class="mr-50"/>
                <span class="align-middle">Rechazar Transferencia</span>
            </b-button> 
            <b-button v-if="this.validDocuments != 'SI'" class="mt-1" variant="outline-primary" block @click="markValidDocuments">
                <feather-icon icon="CheckSquareIcon" class="mr-50"/>
                <span class="align-middle">Marcar Documentos Validos</span>
            </b-button>
        </b-modal>

        <b-modal centered ref="rejectMoneyRequestModal" hide-footer title="Escribe la razón:">            
            <b-form-textarea
                id="textarea-default"
                placeholder="Escribe la razón"
                rows="3"
                v-model="transferRejectedComment"/>
            <b-button class="mt-1" variant="primary" block @click="rejectMoneyRequest">
                <feather-icon icon="SaveIcon" class="mr-50"/>
                <span class="align-middle">Guardar comentario</span>
            </b-button> 
        </b-modal>

        <b-modal centered ref="loadFileModal" hide-footer title="Cargar comprobante">
            <b-form-group label="Cargar comprobante" label-for="basicInput">
                <b-form-file placeholder="Seleccione un documento" v-model="documentFile" />
            </b-form-group>
            <b-button class="mt-1" variant="primary" block @click="approveMoneyRequest">
                <feather-icon icon="CheckIcon" class="mr-50"/>
                <span class="align-middle">Guardar y Aprobar Transferencia</span>
            </b-button>
        </b-modal>

    </div>
</template>

<script>
import axios from '@axios'
import axiosIns from '../../libs/axios'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';
import { BAlert, BTable, BForm, BFormInput, BProgress, BFormFile, BFormGroup, BFormSelect, BSpinner, BButton, BRow, BCol,
BBadge,BFormDatepicker,BFormTextarea, BCardText} from 'bootstrap-vue'

export default {
    components: {
        BFormDatepicker,
        BCardCode,
        BTable,
        BForm,
        BProgress,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BFormTextarea, 
        BCardText,
        BButton,
        BFormSelect,
        BAlert,
        BSpinner,
        BFormFile,
    },
    data(){
        return{
            selectMode: 'single',
            fields: [
                {key: 'fullName', label: 'Nombre'},                
                {key: 'contactEmail', label: 'Email'},
                {key: 'documentType', label: 'Tipo Documento'},
                {key: 'documentNumber', label: 'Número Documento'},
                {key: 'amountToTransfer', label: 'Cantidad Solicitada'},
                {key: 'createDate', label: 'Fecha Solicitud'},
                {key: 'transferStatus', label: 'Estado Transferencia'},
                {key: 'userBalance', label: 'Saldo Disponible'},
                {key: 'bankAccountNumber', label: 'Cuenta'},                
                {key: 'bankAccountType', label: 'Tipo de Cuenta'},
                {key: 'bankAccountBank', label: 'Banco'},                
            ],
            fullName: false,
            contactEmail: null,
            documentType: null,
            documentNumber: null,
            amountToTransfer: null,
            createDate: null,
            documentNumber: null,            
            moneyRequests: [{}], 
            requestSelected:{} ,
            moneyRequestID: null, 
            axiosIns: axiosIns, 
            transferRejectedComment: null,
            email: null,
            documentNumber: null,
            transferStatusList: [
                'Todos',
                'Pendiente',
                'Aprobado',
                'En Proceso',
                'Rechazado'
            ],
            transferStatus: null,
            ExisteFile: null,
            documentFile: null,
            documentUrl: null,
            validDocuments: null,
            userID: null,
            startDate: null,
            endDate: null,
        }
    },
    watch:{
        documentFile: function(val) { this.documentFile = val; this.uploadFile(val, 'documentFile'); },
        startDate: function(val) { this.startDate = val; },
        endDate: function(val) { this.endDate = val; },
    },
    created(){        
        this.transferStatus = 'Pendiente'
        this.getMoneyRequestsToApprove();
    },

    methods:{
        getMoneyRequestsToApprove(){
            var newEmail = '';
            var newDocumentNumber = '';

            if (this.email != undefined || this.email != null) {
                newEmail = this.email.split(' ').join('');
            }

            if (this.documentNumber != undefined || this.documentNumber != null) {
                newDocumentNumber = this.documentNumber.split(' ').join('');
            }
            axios.get('/MoneyRequest/GetMoneyRequestsToApprove',  { params: { email: newEmail,
                documentNumber: newDocumentNumber, status: this.transferStatus, startDate: this.startDate, endDate: this.endDate}})
                .then(response => {                                                                              
                    this.moneyRequests = response.data;                    
                    if(this.moneyRequests > 0){
                        this.moneyRequests.forEach(function(item,index){})
                    }                                  
                })
        },
        onRowSelected(items) {            
            this.requestSelected = items[0]; 
            this.moneyRequestID = this.requestSelected.moneyRequestID
            this.validDocuments = this.requestSelected.validDocuments
            this.userID = this.requestSelected.investorID
            if(this.requestSelected) this.showModal();
        },
        showModal() {
            this.$refs['requestMoneyModal'].show()
        },
        hideModal(){
            this.$refs['requestMoneyModal'].hide()
        },
        approveMoneyRequest(){         
            axios.get('/MoneyRequest/ApproveMoneyTransfer', { params: { moneyRequestID: this.moneyRequestID, documentUrl: this.requestSelected.documentUrl }})
                .then(response => {                                                                              
                    this.moneyRequests = response.data;  
                    if(this.moneyRequests) this.hideModal();  
                    this.$bvToast.toast("Transferencia aceptada", { title: 'Ok', variant: 'success', solid: true})  
                    this.getMoneyRequestsToApprove();
                }).catch(error => {
                    this.hideModal();  
                    if(error.response){
                        this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                    } else {
                        this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                    }
                })
        },
        rejectMoneyRequest(){  
            this.hideModalRejectMoneyRequest();          
            axios.get('/MoneyRequest/RejectMoneyTransfer', { params: { moneyRequestID:this.moneyRequestID, transferRejectedComment:this.transferRejectedComment }})
                .then(response => {                                                                              
                    this.moneyRequests = response.data;  
                    if(this.moneyRequests) this.hideModal();   
                    this.$bvToast.toast("Transferencia rechazada", { title: 'Ok', variant: 'danger', solid: true})  
                    this.getMoneyRequestsToApprove();                                              
            })
        },
        GetMoneyRequestDetailsFile(){
            window.open(this.axiosIns.sostyApiUrl + 'MoneyRequest/GetMoneyRequestDetailsFile?email=' + this.email + '&documentNumber=' + this.documentNumber + '&status=' + this.transferStatus + '&startDate=' + this.startDate + '&endDate=' + this.endDate, '_blank');
        },
        showModalRejectMoneyRequest(){  
            this.hideModal()
            this.$refs['rejectMoneyRequestModal'].show()
        },
        hideModalRejectMoneyRequest(){
            this.$refs['rejectMoneyRequestModal'].hide()
        },
        passToIsInProcess(){
            axios.get('/MoneyRequest/PassTransferToInProcess', { params: { moneyRequestID:this.moneyRequestID}})
            .then(response => {                                                                              
                this.moneyRequests = response.data;  
                if(this.moneyRequests) this.hideModal();   
                this.$bvToast.toast("Transferencia ha cambiado a 'En Proceso'", { title: 'OK', variant: 'success', solid: true, });
                this.hideModalRejectMoneyRequest()
                this.getMoneyRequestsToApprove();
            }).catch(error => {
                this.hideModal();  
                if(error.response){
                    this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                    this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
        },
        openDocument(fileUrl) {
            window.open(fileUrl, '_blank');
        },
        showLoadFileModal(){
            this.hideModal();
            this.$refs['loadFileModal'].show()
        },
        uploadFile(fileToUpload, fileType) {
            this.processing = true;               
            let formData = new FormData();
            formData.append('file', fileToUpload);
            axios.post('/File/UploadFile', formData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                this.processing = false;
                if(response.data) {
                    this.setFileUrl(fileType, response.data);
                    this.$bvToast.toast("Documento subido de forma correcta", { title: 'Ok', variant: 'success', solid: true, });
                    
                } else {
                    this.$bvToast.toast("Ocurrio un problema", { title: 'Email', variant: 'danger', solid: true, })     
                }
        })
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
            })
        },
        setFileUrl(fileType, fileUrl) {
            switch(fileType) {
                case 'documentFile':                    
                    this.requestSelected.documentUrl = fileUrl;
                    break;
                case '':
                    // code block
                    break;
            }
        },
        markValidDocuments(){            
            axios.get('/User/MarkValidDocuments',  { params: { userID:this.userID}})
            .then(response =>{
                if(response){                    
                    this.$bvToast.toast("Información Actualizada", { title: 'Retenedor en la fuente', variant: 'success', solid: true})  
                    window.location.reload()                     
                } else{
                    this.$bvToast.toast("Hubo un problema", { title: 'Ok', variant: 'danger', solid: true})
                }               
            }) 
        },
    },  
}
</script>